// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!./group/toolkit/index.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../components/Feedback/css/index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xk-guiding-btn-wrapper{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-moz-box-pack:end;justify-content:flex-end;.btn{border:none;background:transparent;outline:none;cursor:pointer}.experience-it{font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#fff;line-height:21px;margin-right:14px}.previous-step{margin-right:14px}.next-step,.previous-step{width:75px;height:30px;background:#fff;border-radius:4px;font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#3c89fc;line-height:21px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
